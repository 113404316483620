<template>
  <section class="section example">
    <div class="content">
      <h2 class="content__title section-title text-lp-secondary">
        <span class="text-lp-primary">Goed voor het milieu.</span><br />
        Beter voor je energierekening.
      </h2>
      <div class="content__calc shadow-box">
        <slot name="calculation" />
      </div>
      <div class="content__explanation">
        <slot name="explanation" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExampleSection',
}
</script>

<style lang="scss" scoped>
.section.example {
  @apply mb-12 xl:mb-24;

  .content {
    @apply gap-y-12 xl:gap-y-[4.5rem] md:items-start;

    grid-template-areas:
      'title'
      'calc'
      'explanation';

    @screen md {
      @apply gap-x-5;
      grid-template-areas:
        '. . title title title title title       title       title       title       . .'
        '. . calc  calc  calc  calc  explanation explanation explanation explanation . .';
    }
    &__title {
      grid-area: title;
      @apply text-center;
    }

    &__calc {
      grid-area: calc;
      @apply p-2 xl:p-3;
    }

    &__explanation {
      grid-area: explanation;
    }
  }
}
</style>
