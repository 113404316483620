<template>
  <div id="app" class="landing-page bg-backdrop">
    <LandingPageNavigationBar />
    <main>
      <HeaderSection />
      <ExampleSection />
      <FormContainer>
        <Nuxt />
      </FormContainer>
    </main>
    <Footer class="mt-auto" />
  </div>
</template>

<script>
import FormContainer from 'chimera/all/components/form/FormContainer'
import LandingPageNavigationBar from 'chimera/all/themes/blueflow/components/landingPage/LandingPageNavigationBar.vue'
import HeaderSection from '~/components/landingPage/HeaderSection.vue'
import ExampleSection from '~/components/landingPage/ExampleSection.vue'
import Footer from '~/components/layout/Footer.vue'

export default {
  name: 'FormLayout',

  components: {
    Footer,
    LandingPageNavigationBar,
    HeaderSection,
    FormContainer,
    ExampleSection,
  },
}
</script>
