<template>
  <div id="app" class="landing-page">
    <LandingPageNavigationBar />
    <main>
      <nuxt />
    </main>
    <Footer logo-color-class="text-lp-primary" />
  </div>
</template>

<script>
import LandingPageNavigationBar from 'chimera/all/themes/blueflow/components/landingPage/LandingPageNavigationBar'
import Footer from '~/components/layout/Footer.vue'

export default {
  name: 'LandingPage',

  components: {
    LandingPageNavigationBar,
    Footer,
  },
}
</script>
