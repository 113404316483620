<template>
  <NavigationBar :logo="logo" />
</template>

<script>
import NavigationBar from 'chimera/all/themes/blueflow/components/layout/NavigationBar.vue'

export default {
  name: 'LandingPageNavigationBar',

  components: {
    NavigationBar,
  },

  props: {
    logo: {
      type: String,
      default: 'images/logo.svg',
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  @apply absolute border-0;
  background-color: rgba(17, 0, 0, 0.2);

  .navbar__container {
    @apply md-down:min-h-[64px];
  }

  ::v-deep .navbar__logo > g {
    > path {
      @apply fill-white;
    }

    > g {
      @apply stroke-white;
    }
  }
}
</style>
